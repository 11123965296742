<template>
    <v-layout column>
        <v-card class="mb-4">
            <v-card-title v-t="'products.myOpen.headline'" class="primary--text" />
        </v-card>
        <product-list type="myOpen" />
    </v-layout>
</template>

<script>
import ProductList from '../components/products/ProductList'

export default {
    name: 'Products',
    components: {
        ProductList
    }
}
</script>
