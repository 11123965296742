
































import Component from 'vue-class-component'
import Vue from 'vue'
import { mdiDelete } from '@mdi/js'
import { Prop } from 'vue-property-decorator'
import { apolloClient } from '@/graphQl/vue-apollo'
import { deleteProductsMutation } from '@/graphQl/queryBuilder'

@Component
class ModalDeleteProduct extends Vue {
    @Prop({ type: Array as () => Number[], required: true })
    readonly ids!: number[]

    @Prop({ type: Array as () => String[], required: true })
    readonly names!: string[]

    @Prop({ type: Boolean, required: true })
    readonly disabled!: boolean

    @Prop({ type: Function, required: true })
    readonly onDeleted!: () => Promise<any>

    svgDelete = mdiDelete
    isOpen = false

    // noty
    private $noty!: NotyObj

    private async onDeletebutton() {
        this.isOpen = false
        try {
            const deleteMutation = deleteProductsMutation(this.ids)
            const response = await apolloClient.mutate(deleteMutation)
            const statusCodes: number[] = response.data.deleteProducts.map((p: any) => p.statusCode)
            if (statusCodes.every(code => code === 200)) {
                this.$noty.success(this.$i18n.t('products.deleteProducts.messageSuccess', [this.names]) as string, {
                    timeout: 2000
                })
            } else {
                this.$noty.error(this.$i18n.t('products.deleteProducts.messageDelete') + statusCodes.toString())
            }
            this.onDeleted()
        } catch (e) {
            this.$noty.error(this.$i18n.t('products.deleteProducts.messageDelete') + e?.statusText)
            throw e
        }
    }
}

export default ModalDeleteProduct
