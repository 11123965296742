<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12" :sm="isArchive ? 6 : 4" md="6" :lg="isArchive ? 4 : 3">
                    <v-text-field
                        v-model="search"
                        :append-icon="svgSearch"
                        :label="$t('product.name.search')"
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col v-if="!isArchive" cols="12" sm="4" md="3" lg="2">
                    <v-select
                        v-model="segmentation"
                        :items="segmentations"
                        :label="$t('product.segmentation.label')"
                        :multiple="false"
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col cols="12" :sm="isArchive ? 6 : 4" :md="isArchive ? 6 : 3" lg="2">
                    <v-select
                        v-model="displayOnCoatinoWebsite"
                        :items="productStates"
                        :label="$t('product.status.label')"
                        :multiple="false"
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col cols="12" :lg="isArchive ? 6 : 5">
                    <v-select
                        v-model="productGroup"
                        :disabled="!productGroups || !productGroups.length"
                        :items="productGroups"
                        :label="$t('product.productGroupWebsite.label')"
                        :multiple="false"
                        hide-details
                        clearable
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-data-table
            v-model="selectedProducts"
            :headers="headers"
            :items="products"
            :options.sync="options"
            :server-items-length="totalCount"
            :loading="loading"
            :loading-text="$t('productList.loading')"
            :no-data-text="$t('productList.noDataFound')"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
            show-select
            class="elevation-1 product-list"
            @item-selected="restrictMaxSelections"
            @click:row="itemClicked"
        >
            <template #item.productGroupWebsite="{ item }">{{ translateProductGroup(item) }}</template>
            <template #item.segmentation="{ item }">{{ translateSegmentation(item) }}</template>
            <template #item.displayOnCoatinoWebsite="{ item }">{{ translateStatus(item) }}</template>
            <template #footer>
                <div class="products-buttons row">
                    <v-btn
                        class="ml-3"
                        :disabled="selectedProducts.length < 1"
                        color="primary"
                        @click="openSelectedProductsClicked"
                    >
                        {{ $t('productList.openSelectedProductsButton') }}
                    </v-btn>
                    <modal-delete-product
                        v-if="isAdministrator && type === 'archive'"
                        :ids="selectedProducts.map(p => p.id)"
                        :names="selectedProducts.map(p => p.name)"
                        :disabled="selectedProducts.length < 1"
                        :on-deleted="onDelete"
                    />
                    <v-btn v-if="selectedProducts.length > 0" text disabled>
                        ({{ selectedProducts.length }}/4 selected)
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { extractAndDisplayError, handleFetchResponse } from '@/utils'
import { mdiMagnify } from '@mdi/js'
import ModalDeleteProduct from '@/components/products/ModalDeleteProduct'

export default {
    name: 'ProductList',
    components: { ModalDeleteProduct },
    props: {
        type: {
            type: String,
            default: 'active',
            validator: value => {
                return ['active', 'archive', 'my', 'myOpen', 'open'].indexOf(value) > -1
            }
        }
    },
    data: function () {
        return {
            svgSearch: mdiMagnify,
            search: '',
            productGroup: null,
            segmentation: null,
            displayOnCoatinoWebsite: null,
            totalCount: 0,
            loading: false,
            options: {},
            products: [],
            selectedProducts: []
        }
    },
    computed: {
        ...mapGetters('product', ['productStates', 'segmentations']),
        ...mapGetters('auth', ['allowedActions', 'isAdministrator', 'restrictedProductGroups']),
        ...mapState('product', {
            allProductGroups: 'productGroups'
        }),
        dataUrl() {
            const urls = {
                active: '/api/products/search/active',
                archive: '/api/products/search/archived',
                my: '/api/products/search/my',
                myOpen: '/api/products/search/myOpen',
                open: '/api/products/search/open'
            }
            return urls[this.type] || false
        },
        productGroups() {
            if (this.isAdministrator) {
                return this.allProductGroups
            } else if (['my', 'myOpen'].indexOf(this.type) > -1) {
                if (this.restrictedProductGroups && this.restrictedProductGroups.length) {
                    return this.allProductGroups.filter(g => {
                        return this.restrictedProductGroups.indexOf(g.value) > -1
                    })
                }
            }
            return this.allProductGroups
        },
        isArchive() {
            return this.type === 'archive'
        },
        headers() {
            const headers = [
                { text: this.$t('product.name.label'), value: 'name' },
                { text: this.$t('product.id.label'), value: 'id' },
                { text: this.$t('product.msdsId.label'), value: 'msdsId' },
                { text: this.$t('product.productGroupWebsite.label'), value: 'productGroupWebsite' }
            ]
            if (!this.isArchive) {
                headers.push({ text: this.$t('product.segmentation.label'), value: 'segmentation' })
            }
            headers.push({ text: this.$t('product.status.label'), value: 'displayOnCoatinoWebsite' })
            return headers
        }
    },
    watch: {
        options: {
            handler: function () {
                this.updateData()
            },
            deep: true
        },
        search: function () {
            this.setToFirstPage()
        },
        productGroup: function () {
            this.setToFirstPage()
        },
        segmentation: function () {
            this.setToFirstPage()
        },
        displayOnCoatinoWebsite: function () {
            this.setToFirstPage()
        }
    },
    methods: {
        translateProductGroup(product) {
            const found = (this.allProductGroups || []).find(entry => entry.value === product.productGroupWebsite)
            return found ? found.text : '-'
        },
        translateSegmentation(product) {
            const found = (this.segmentations || []).find(entry => entry.value === product.segmentation)
            return found ? found.text : '-'
        },
        translateStatus(product) {
            const found = (this.productStates || []).find(entry => entry.value === product.displayOnCoatinoWebsite)
            return found ? found.text : '-'
        },
        getQueryParams() {
            const searchParams = new URLSearchParams()
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            const params = {
                page: page - 1,
                size: itemsPerPage || 10
            }
            if (sortBy.length) {
                params.sort = `${sortBy[0]},${sortDesc.length && sortDesc[0] ? 'DESC' : 'ASC'}`
            }

            if (this.search) {
                params.partialProductName = this.search
            }
            if (this.productGroup) {
                params.productGroupWebsite = this.productGroup
            }
            if (!this.isArchive && this.segmentation) {
                params.segmentation = this.segmentation
            }

            if (this.displayOnCoatinoWebsite !== null && this.displayOnCoatinoWebsite !== undefined) {
                params.displayOnCoatinoWebsite = this.displayOnCoatinoWebsite
            }

            Object.keys(params).forEach(key => searchParams.set(key, params[key]))

            return searchParams.toString()
        },
        async updateData() {
            if (!this.dataUrl) {
                return
            }

            this.loading = true

            const separator = this.dataUrl.indexOf('?') > -1 ? '&' : '?'
            const currentSearch = this.search
            try {
                const response = await fetch(`${this.dataUrl}${separator}${this.getQueryParams()}`)
                const data = await handleFetchResponse(response)
                if (currentSearch === this.search) {
                    this.products = data._embedded.products
                    this.totalCount = data.page.totalElements
                    this.loading = false
                }
            } catch (err) {
                extractAndDisplayError(err, this.$noty)
                throw err
            }
        },
        restrictMaxSelections(params) {
            if (this.selectedProducts.length >= 4 && params.value) {
                this.selectedProducts = this.selectedProducts.splice(0, 4)
            }
        },
        itemClicked(item, singleSelected) {
            if (this.selectedProducts.length === 0 && singleSelected) {
                this.openProducts(item.id)
            }
            if (this.selectedProducts.length >= 1 && this.selectedProducts.length < 5 && !singleSelected.isSelected) {
                singleSelected.select()
            }
            if (this.selectedProducts.find(product => product.id) && singleSelected.isSelected) {
                this.selectedProducts = this.selectedProducts.filter(p => p.id !== item.id)
            }
        },
        openSelectedProductsClicked() {
            const ids = this.selectedProducts.map(product => product.id).join()
            this.openProducts(ids)
        },
        openProducts(ids) {
            let query = null
            if (['my', 'myOpen'].indexOf(this.type) > -1) {
                query = { edit: 1 }
            }
            this.$router.push({ name: 'product', params: { id: ids }, query })
        },
        onDelete() {
            this.selectedProducts = []
            this.updateData()
        },
        setToFirstPage() {
            if (this.options.page !== 1) {
                this.options.page = 1
            } else {
                this.updateData()
            }
        }
    }
}
</script>

<style lang="scss">
.product-list {
    thead th:nth-child(1) > div {
        display: none;
    }

    tbody tr {
        cursor: pointer;

        td:first-child {
            font-weight: bold;
            color: #991d85;
        }
    }

    .products-buttons {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
}
</style>
